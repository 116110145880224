<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation>
			<ti-card :icon="$icons.training">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Training</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this training_course?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>
				<ti-form-layout>
					<template #sidebar>
						<v-col cols="12" lg="8">
								<v-checkbox v-model="training_course.active" label="Active"></v-checkbox>
						</v-col>
					</template>
					<template>
						<v-col cols="12" lg="8">
							<v-text-field v-model="training_course.title" :rules="validations.required('Title')" label="Title"></v-text-field>
						</v-col>

						<v-col cols="12" lg="8">
							<v-text-field v-model="training_course.subtitle" :rules="validations.required('Subtitle')" label="Subtitle"></v-text-field>
						</v-col>

						<v-col cols="12" lg="8">
							<v-text-field v-model="training_course.caption" :rules="validations.required('Caption')" label="Caption"></v-text-field>
						</v-col>
					</template>
				</ti-form-layout>

				<ti-form-layout title="Section 1">
					<template>
						<v-col cols="12" lg="8">
							<v-text-field v-model="training_course.section_1_title" :rules="validations.required('Section 1 Title')" label="Section 1 Title"></v-text-field>
						</v-col>

						<v-col cols="12" lg="8">
							<ti-editor v-model="training_course.section_1_text" :rules="validations.required('Section 1 Text')" label="Section 1 Text"></ti-editor>
						</v-col>
					</template>
				</ti-form-layout>

				<ti-form-layout title="Section 2">
					<template>
						<v-col cols="12" lg="8">
							<v-text-field v-model="training_course.section_2_title" :rules="validations.required('Section 2 Title')" label="Section 2 Title"></v-text-field>
						</v-col>

						<v-col cols="12" lg="8">
							<ti-editor v-model="training_course.section_2_text" :rules="validations.required('Section 2 Text')" label="Section 2 Text"></ti-editor>
						</v-col>

						<v-col cols="12">
							<h4 class="text-h4">LIST</h4>
						</v-col>

						<v-col cols="12" lg="8">
							<h6>LIST ITEMS</h6>
							<v-row v-for="(item, index) in training_course.section_2_list">
								<v-col>
									<v-text-field v-model="training_course.section_2_list[index]" :label="`Item: ${index}`"></v-text-field>
								</v-col>
								<v-col cols="auto">
									<v-btn text @click="training_course.section_2_list.splice(index, 1)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="auto">
									<v-btn class="success" fab @click="training_course.section_2_list.push('')">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {call, sync} from "vuex-pathify";
import models from "../../models";
import TiEditor from "../../components/TiEditor";

export default {

	name: "TrainingCoursesEdit",
	components: {TiEditor},
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		training_course: sync('training_courses/trainingCourse')
	},
	data: () => ({
		search: '',
		valid: false,
		validations: {
			required: (fieldName) => validationRules(fieldName, ['required', 'minLength:2']),
		},
		resourceHeaders:[
			{
				text: 'Title',
				value: 'title',
			},
		]
	}),
	beforeMount() {
		this.init();
	},
	methods: {
		getTrainingCourse: call('training_courses/getTrainingCourse'),
		init() {
			if (this.isEdit) {
				this.getTrainingCourse(this.id)
			} else {
				this.training_course = models.TrainingCourse()
			}
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.training_courses.update(this.id, this.training_course)
						.then(response => {
							this.$toast.add('Topic updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Topics'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				} else {
					this.$api.training_courses.create(this.training_course)
						.then(response => {
							this.$toast.add('Topic created successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Topics'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		editResource(resource) {
			let plural = resource.type === 'CaseStudy' ? 'Case_studies' : `${resource.type}s`;
			this.$router.push({name: `${plural}Edit`, params: {id: resource.id}})
		},
		destroy() {
			this.$api.training_courses.delete(this.id)
				.then(response => {
					this.$toast.add('Topic deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'Topics'})
				})
				.catch(error => {
					console.log(error.response);
					console.log('should show error');
					this.$toast.handleResponseErrors(this, error)
				})
		},
	}
}
</script>